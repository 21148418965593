import {
  CALCULATION_SET_POWER,
  CALCULATION_SET_KWH,
  CALCULATION_SET_EMAIL,
  CALCULATION_SET_FLOWERING_TYPE,
  CALCULATION_SET_TIME_ON,
  CALCULATION_SET_RESULT,
  CALCULATION_SET_SHOW_RESULT,
} from '../actions';

const INIT_STATE = {
  power: 250,
  kwh: 0.65,
  email: '',
  // voltage: 110,
  timeOn: 18,
  result: 0,
  type: 'reg',
  showResult: false,
};

const calculationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CALCULATION_SET_POWER:
      return { ...state, power: action.payload };

    case CALCULATION_SET_KWH:
      return { ...state, kwh: action.payload };

    case CALCULATION_SET_EMAIL:
      return { ...state, email: action.payload };

    case CALCULATION_SET_FLOWERING_TYPE:
      return { ...state, type: action.payload };

    case CALCULATION_SET_TIME_ON:
      return { ...state, timeOn: action.payload };

    case CALCULATION_SET_RESULT:
      return { ...state, result: action.payload };

    case CALCULATION_SET_SHOW_RESULT:
      return { ...state, showResult: action.payload };

    default:
      return { ...state };
  }
};
export { calculationReducer };
