import {
  CALCULATION_SET_POWER,
  CALCULATION_SET_KWH,
  CALCULATION_SET_EMAIL,
  CALCULATION_SET_FLOWERING_TYPE,
  CALCULATION_SET_TIME_ON,
  CALCULATION_SET_RESULT,
  CALCULATION_SET_SHOW_RESULT,
} from '../actions';

export const setPower = (payload) => {
  return {
    type: CALCULATION_SET_POWER,
    payload,
  };
};
export const setKwh = (payload) => {
  return {
    type: CALCULATION_SET_KWH,
    payload,
  };
};
export const setEmail = (payload) => {
  return {
    type: CALCULATION_SET_EMAIL,
    payload,
  };
};
export const setFloweringType = (payload) => {
  return {
    type: CALCULATION_SET_FLOWERING_TYPE,
    payload,
  };
};
export const setTimeOn = (payload) => {
  return {
    type: CALCULATION_SET_TIME_ON,
    payload,
  };
};
export const setResult = (payload) => {
  return {
    type: CALCULATION_SET_RESULT,
    payload,
  };
};
export const setShowResult = (payload) => {
  return {
    type: CALCULATION_SET_SHOW_RESULT,
    payload,
  };
};
